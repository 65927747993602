

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DiceDefinitionItem extends Vue {
  @Prop() label!: string;
  @Prop({ default: "green-500" }) color!: string;
}
