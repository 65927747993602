
















































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import PlusIcon from "@/assets/PlusIcon.vue";
import DiceFeatureItem from "@/assets/DiceFeatureItem.vue";
import DiceDefinitionItem from "@/assets/DiceDefinitionItem.vue";
import DottedPattern from "@/assets/DottedPattern.vue";

@Component({
  components: {
    DottedPattern,
    PlusIcon,
    DiceDefinitionItem,
    DiceFeatureItem
  }
})
export default class Dice extends Vue {
  @Prop() private msg!: string;
}
