<template>
  <div class="home">
    <Splash />
    <Dice />
    <Ares />
    <About />
    <Services />
    <Tools />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Splash from "@/components/Splash";
import Ares from "@/components/Ares";
import Dice from "@/components/Dice";
import About from "@/components/About";
import Services from "@/components/Services";
import Tools from "@/components/Tools";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Splash,
    Ares,
    Dice,
    About,
    Services,
    Tools,
    Footer
  }
};
</script>
